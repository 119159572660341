/* roboto-mono-100normal - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Roboto Mono Thin '),
    local('Roboto Mono-Thin'),
    url('./files/roboto-mono-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-mono-latin-100.woff') format('woff'); /* Modern Browsers */
}

/* roboto-mono-100italic - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('Roboto Mono Thin italic'),
    local('Roboto Mono-Thinitalic'),
    url('./files/roboto-mono-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-mono-latin-100italic.woff') format('woff'); /* Modern Browsers */
}

/* roboto-mono-200normal - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Roboto Mono Extra Light '),
    local('Roboto Mono-Extra Light'),
    url('./files/roboto-mono-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-mono-latin-200.woff') format('woff'); /* Modern Browsers */
}

/* roboto-mono-200italic - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src:
    local('Roboto Mono Extra Light italic'),
    local('Roboto Mono-Extra Lightitalic'),
    url('./files/roboto-mono-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-mono-latin-200italic.woff') format('woff'); /* Modern Browsers */
}

/* roboto-mono-300normal - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Roboto Mono Light '),
    local('Roboto Mono-Light'),
    url('./files/roboto-mono-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-mono-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* roboto-mono-300italic - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Roboto Mono Light italic'),
    local('Roboto Mono-Lightitalic'),
    url('./files/roboto-mono-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-mono-latin-300italic.woff') format('woff'); /* Modern Browsers */
}

/* roboto-mono-400normal - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Roboto Mono Regular '),
    local('Roboto Mono-Regular'),
    url('./files/roboto-mono-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-mono-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* roboto-mono-400italic - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Roboto Mono Regular italic'),
    local('Roboto Mono-Regularitalic'),
    url('./files/roboto-mono-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-mono-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* roboto-mono-500normal - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Roboto Mono Medium '),
    local('Roboto Mono-Medium'),
    url('./files/roboto-mono-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-mono-latin-500.woff') format('woff'); /* Modern Browsers */
}

/* roboto-mono-500italic - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Roboto Mono Medium italic'),
    local('Roboto Mono-Mediumitalic'),
    url('./files/roboto-mono-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-mono-latin-500italic.woff') format('woff'); /* Modern Browsers */
}

/* roboto-mono-600normal - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Roboto Mono SemiBold '),
    local('Roboto Mono-SemiBold'),
    url('./files/roboto-mono-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-mono-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* roboto-mono-600italic - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Roboto Mono SemiBold italic'),
    local('Roboto Mono-SemiBolditalic'),
    url('./files/roboto-mono-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-mono-latin-600italic.woff') format('woff'); /* Modern Browsers */
}

/* roboto-mono-700normal - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Roboto Mono Bold '),
    local('Roboto Mono-Bold'),
    url('./files/roboto-mono-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-mono-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* roboto-mono-700italic - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Roboto Mono Bold italic'),
    local('Roboto Mono-Bolditalic'),
    url('./files/roboto-mono-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-mono-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

